/* autoprefixer grid: autoplace */

.form-group {
  margin-bottom: 35px;
  margin-top: 35px;
  input[type="text"], input[type="password"] {
    border-radius: 0px;
    border: none;
  }
  .form-text {
    color: #fff;
    margin-top: 6px;
    margin-left: 12px;
    font-size: 12px;
  }
}

.create-user {  
  .form-group {
    margin-bottom: 25px;
    margin-top: 25px;
    input[type="text"], input[type="password"] {
      border: 1px solid #000;
    }
    .form-text {
      color: #000;
      margin-top: 6px;
      margin-left: 12px;
      font-size: 12px;
    }
  }
}


.loe-button {
  cursor: pointer;
  font-weight: 300;
  align-self: center;
  padding-left: 25px;
  margin-left: 20px;
  color: black;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    text-decoration: none;
    color: gray;
  }
}