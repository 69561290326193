

.col-12 {
  .user-card {
    position: relative;
    background: #FAFAFA;
    padding: 15px;
    border: 1px solid #eee;
    margin-bottom: 0px;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(153,153,153,1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(153,153,153,1);
    box-shadow: 0px 2px 4px 0px rgba(153,153,153,1);

    .avatar-holder {
      display: flex;
      justify-content: space-around;
    }
    .name-email {
      color: #2F4858;
      line-height: 1.2;
      display: flex;
      justify-content: space-around;
      .email {
        font-size: 12px;
      }
    }
    .date-time {
      color: #747474;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
    }
    .edit-user {
      cursor: pointer;
      top: 2px;
      right: 8px;
      position: absolute;
      z-index: 1;
      .fas {
        font-size: 14px;
        color: #2F4858;
        padding-right: 4px;
      }
    }
    .fa-user-circle {
      color: #2F4858;
      font-size: 40px;
    }
  }
}

.col-4 {
  .user-card {
    margin-bottom: 20px;
  }
}