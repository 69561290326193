html {
  height: 100%;
}
body {
  height: 100%;
  background: #818e96; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #818e96 0%, #3a4145 72%, #2f3538 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #818e96 0%,#3a4145 72%,#2f3538 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #818e96 0%,#3a4145 72%,#2f3538 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#818e96', endColorstr='#2f3538',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.base-view {
  background: #2b4150; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #2b4150 0%, #1a2730 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #2b4150 0%,#1a2730 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #2b4150 0%,#1a2730 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2b4150', endColorstr='#1a2730',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  margin-top: 50px;
  padding: 60px;

  @media (min-width: 992px) {
      max-width: 770px;
  }

  .nav-items {
    border-bottom: 1px solid #969696;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .col {
      font-size: 28px;
      a {
        color: #596D79;
        &.is-active {
          color: #fff;
        }
      }
    }
  }
}